import React from 'react'
import PropTypes from 'prop-types'
import remark from 'remark'
import remarkHTML from 'remark-html'

export const HTMLContent = ({content, className}) => (
  <div className={className} dangerouslySetInnerHTML={{__html: content}} />
)

export const MarkdownContent = ({content, className}) => (
  <div
    className={`custom-content ${className}`}
    dangerouslySetInnerHTML={{
      __html: remark().use(remarkHTML).processSync(content).toString(),
    }}
  />
)

const Content = ({content, className}) => (
  <div className={className}>{content}</div>
)

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
}

HTMLContent.propTypes = Content.propTypes

export default Content
